footer {
  position: fixed; /* Here's what sticks it */
  bottom: 0; /* to the bottom of the window */
  left: 0; /* and to the left of the window. */
  margin: 0;
  width: 100%;
  background: rgb(13, 110, 253);
  color: #ffffff;
  text-align: center;
  z-index: 1001;
}
