.calendar-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 56px;
  padding: 1rem;
  padding-bottom: 56px;

  // &__card {
  //   width: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  // }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.calendar-page .col {
  width: 100%;
}

.calendar-page .card-body {
  width: 100%;
}

.calendar-page .card {
  margin: 1rem 0;
}

.td__buttons {
  margin: 1rem;
}

td {
  display: flexbox;
  flex-direction: row;
}
