.users-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 56px;
  padding: 1rem;
  padding-bottom: 56px;

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__buttons-danger {
    margin-left: 1rem;
  }

  &__buttons-save {
    margin-top: 1rem;
  }
}

.users-page .users-page__img {
  width: 50%;
  height: 100%;
  margin: 1rem;
  border-radius: 50%;
}

.users-page .card-title {
  display: flex;
  justify-content: center;
}
