.profile-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 56px;
  padding: 1rem;
  padding-bottom: 56px;

  &__card {
    display: flex;
    justify-content: center;
  }

  &__container {
    display: flex;
    justify-content: center;
  }

  &__img {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    padding: 1rem;
    display: flex;
    justify-content: center;
  }

  &__input {
  }

  &__buttons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__buttons-danger {
    margin-left: 1rem;
  }

  &__buttons-password {
    margin-right: 1rem;
  }

  &__buttons-save {
    margin-top: 1rem;
  }

  &__error {
    color: red;
  }
}
