.login-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 56px;

  &__sub-header {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__sub-section {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__sub-container {
    justify-content: center;
    width: 75%;
    border-radius: 20px;
  }

  &__form {
    padding: 20px;
  }
}

.login__message {
  color: red;
}

form .form-floating {
  margin: 1rem 0;
}

form .login__btn {
  margin: 0 0 1rem 0;
}

.login-page .bg-body-tertiary {
  background-color: rgba(76, 191, 245, 0.623) !important;
}
