.projects-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 56px;
  padding: 1rem;
  padding-bottom: 56px;
}

.projects-page__search-container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.projects-page__search-project {
  display: flex;
  margin: 1rem 0;
  margin-bottom: 0;
}

.projects-page__sort-by {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  margin-top: 0;
}

.projects-page .projects-page__img {
  width: 50%;
  height: 100%;
  margin: 1rem;
  border-radius: 50%;
}

.projects-page__Comment-Img {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 1rem;
}

.projects-page__Comment {
  display: flex;
}

.projects-page__Comment-user {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.projects-page__assign-task {
  display: flex;
  flex-direction: column;
}

.projects-page__Comment-user-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.col .card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projects-page .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projects-page__Completed {
  text-decoration: line-through;
  color: gray;
}

.projects-page__task-container {
  display: flex;
  justify-content: flex-start;
}

.projects-page__task-container .form-check-label {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.5rem;
}

.projects-page__task-date {
  display: flex;
  flex-direction: column;
  font-style: italic;
  color: red;
}

.projects-page__task-list {
  width: 75%;
}

li,
ul {
  text-decoration: none;
  list-style-type: none;
  padding: 0;
}

.projects-page__dropdown-button {
  margin-top: 1rem;
}
